import {IonAlert, IonButton, IonIcon} from "@ionic/react";
import {arrowUpCircle} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";

function AppUpdateInfo(props) {
    const [availableVersion, setAvailableVersion] = useState(true);
    const [newVersion, setNewVersion] = useState({version: props.currentVersion, features: []});
    useEffect(() => {
        if (props.online) {
            fetch(`./version.info?k=${moment().unix()}`)
                .then(response => {
                    return response.json();
                }).then(data => {
                setNewVersion(data.version);
                setAvailableVersion(data.version !== props.currentVersion);
            })
        }
    }, [props.currentVersion, props.online]);
    return (<>
            <IonButton id="update-info-alert">
                <IonIcon slot="icon-only" icon={arrowUpCircle}
                         color={availableVersion ? "danger" : null}></IonIcon>
            </IonButton>
            {availableVersion && (<IonAlert
                header="Доступно обновление"
                subHeader={`Версия ${newVersion}`}
                trigger="update-info-alert"
                buttons={[
                    {
                        text: 'Закрыть',
                        role: 'cancel'
                    },
                    {
                        text: 'Обновить',
                        role: 'confirm',
                        handler: () => {
                            if ('serviceWorker' in navigator) {
                                navigator.serviceWorker.ready.then(registration => {
                                    registration.unregister();
                                    if (caches) {
                                        // Service worker cache should be cleared with caches.delete()
                                        caches.keys().then(async (names) => {
                                            await Promise.all(names.map(name => caches.delete(name)));
                                            // eslint-disable-next-line no-restricted-globals
                                            location.reload();
                                        });
                                    }
                                });
                            }
                        },
                    },
                ]}
            ></IonAlert>)}
            {!availableVersion && (<IonAlert
                header={"Нет доступных обновлений"}
                trigger="update-info-alert"
                subHeader={`Версия ${props.currentVersion}`}
                buttons={[
                    {
                        text: 'Закрыть',
                        role: 'cancel',
                    }
                ]}
            ></IonAlert>)}
        </>
    );
}

export default AppUpdateInfo;