import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React from "react";

function PaymentWidget(props) {
    return (
        <IonRow class="ion-justify-content-center">
            <IonCol size="12" size-xs="12" size-sm="12" size-md="10" size-lg="4">
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Выплаты</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow class="ion-justify-content-center">
                                <IonCol size="12" size-xs="12" size-sm="12" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Аванс, руб. ({props.prePaidDate})</IonLabel>
                                        <IonInput readonly={true} value={props.prePaid}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12" size-xs="12" size-sm="12" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Зарплата, руб. ({props.paidDate})</IonLabel>
                                        <IonInput readonly={true} value={props.paid}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12" size-xs="12" size-sm="12" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Выплачено, руб.</IonLabel>
                                        <IonInput readonly={true} value={props.fullPaid}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="12" size-xs="12" size-sm="12" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Удержано, руб.</IonLabel>
                                        <IonInput readonly={true} value={props.withheld}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>Представленный расчет приблизительный и носит информационный характер</p>
                    </IonCardContent>

                </IonCard>
            </IonCol>
        </IonRow>
    )
}

export default PaymentWidget;