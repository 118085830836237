import moment from "moment/moment";

const FORMAT_DATE_RU = "DD.MM.YYYY";
const FORMAT_DATE = "YYYY-MM-DD";

export function parsePositiveIntegerNumber(rawNumber) {
    let filteredValue = rawNumber.replace(/[^0-9*[.]]+/g, '');
    while (filteredValue.length > 1 && filteredValue[0] === '0' && filteredValue[1] !== '.') {
        filteredValue = filteredValue.substring(1);
    }
    return filteredValue.length > 0 ? filteredValue : '0';
}

export function isTrue(value) {
    return value === 'true';
}

export function calculateCostHour(salary, hourOfMonth) {
    return Number(salary) / hourOfMonth;
}

export function getHourOfMonth(calendarMonthInfoData, period) {
    const date = [period.full];
    const month = calendarMonthInfoData.filter(month => month[date]);
    return month.length > 0 ? month[0][date].full : 0;
}

export function getHourFirstHalfOfMonth(calendarMonthInfoData, period) {
    const date = [period.full];
    const value = calendarMonthInfoData.filter(item => item[date]);
    return value.length > 0 ? value[0][date].firstHalf : 0;
}

export function getHourSecondHalfOfMonth(calendarMonthInfoData, period) {
    const date = [period.full];
    const month = calendarMonthInfoData.filter(month => month[date]);
    return month.length > 0 ? month[0][date].secondHalf : 0;
}

export function calculateSalary(cost, hours, tax, northernAllowance, regionalCoefficient, otherPaid, royaltyPaid, remoteWorkPaid) {
    let salary = cost * hours;
    let northernAllowanceValue = 0;
    if (northernAllowance && northernAllowance !== "0") {
        northernAllowanceValue = salary * getNorthernAllowance(northernAllowance);
    }
    if (regionalCoefficient && regionalCoefficient !== "0") {
        salary = salary * Number(regionalCoefficient);
    }
    salary = salary + northernAllowanceValue;
    if (otherPaid && otherPaid !== "0") {
        salary = salary + Number(otherPaid);
    }
    if (royaltyPaid && royaltyPaid !== "0") {
        salary = salary + Number(royaltyPaid);
    }
    if (tax && tax !== 0) {
        salary = salary * getTax(tax);
    }
    if (remoteWorkPaid && remoteWorkPaid !== "0") {
        salary = salary + Number(remoteWorkPaid);
    }
    return salary.toFixed(2);
}

export function calculateFullPaid(prePaid, paid) {
    return (Number(prePaid) + Number(paid)).toFixed(2);
}

export function calculateWithheld(salary, tax, otherPaid, royaltyPaid) {

    return salary === "0" ? "0" : ((Number(salary) + Number(otherPaid) + Number(royaltyPaid)) * Number(tax) / 100).toFixed(2);
}

export function getTax(tax) {
    return (100 - Number(tax)) / 100;
}

export function getNorthernAllowance(northernAllowance) {
    return Number(northernAllowance) / 100;
}

export function getCurrentDate() {
    const year = new Date().getUTCFullYear().toString();
    const month = moment().format("MM");
    return {year, month, full: `${year}-${month}`};
}

export function getPrePaidDate(calendarHolidaysData, period, dayPrePaid) {
    let prePaidDate = moment(`${period.full}-${dayPrePaid}`, FORMAT_DATE);
    // eslint-disable-next-line no-loop-func
    while (calendarHolidaysData.filter(date => date.startsWith(prePaidDate.format(FORMAT_DATE))).length !== 0) {
        prePaidDate = prePaidDate.subtract(1, 'days');
    }
    return prePaidDate.format(FORMAT_DATE_RU);
}

export function getPaidDate(calendarHolidaysData, period, dayPaid) {
    let paidDate = moment(`${period.full}-${dayPaid}`, FORMAT_DATE).add(1, 'months');
    // eslint-disable-next-line no-loop-func
    while (calendarHolidaysData.filter(date => date.startsWith(paidDate.format(FORMAT_DATE))).length !== 0) {
        paidDate = paidDate.subtract(1, 'days');
    }
    return paidDate.format(FORMAT_DATE_RU);
}

export function getMonthInfo(year, month, calendarMonthInfoData) {
    const date = `${year}-${month}`;
    const monthInfo = calendarMonthInfoData.filter(month => month[date]);
    let workDay = 0;
    let holiday = 0;
    let fullHours = 0;
    if (monthInfo.length > 0) {
        workDay = monthInfo[0][date].workDay;
        holiday = monthInfo[0][date].holiday;
        fullHours = monthInfo[0][date].full;
    }
    return `Кол-во дней: рабочих - ${workDay}, вых./праздн. - ${holiday}. Рабочие часы: ${fullHours}`;
}

export function getBonus(coefficient, salary, tax, bonusEnabled) {
    console.log(bonusEnabled);
    if (bonusEnabled) {
        if (tax && Number(tax) !== 0) {
            salary = salary * getTax(tax);
        }
        return (Number(coefficient) * Number(salary) * 12 / 100).toFixed(2);
    } else {
        return 0;
    }
}

export function getDaysLeftUntilBonus() {
    return moment([2025, 2, 31]).diff(moment(),'days')
}

export function christmasThemeShow() {
    const dayOfYear = moment().dayOfYear();
    return dayOfYear < 15 || dayOfYear > 341
}