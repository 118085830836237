import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {getMonthInfo} from "../../Utils";

function BillingPeriodWidget(props) {
    const [year, setYear] = useState(props.period.year);
    const [month, setMonth] = useState(props.period.month);
    const [monthInfo, setMonthInfo] = useState("");
    useEffect(() => {
        setMonthInfo(getMonthInfo(year, month, props.calendarMonthInfoData))
    }, [year, month, props.calendarMonthInfoData]);

    return (
        <IonRow class="ion-justify-content-center">
            <IonCol size="12" size-xs="12" size-sm="12" size-md="10" size-lg="4">
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Расчетный период</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow class="ion-justify-content-center">
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Год</IonLabel>
                                        <IonSelect value={year} placeholder="Выберите год" okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       const value = e.detail.value;
                                                       setYear(e.detail.value);
                                                       props.change({year: value, month});
                                                   }}>
                                            <IonSelectOption value="2023">2023</IonSelectOption>
                                            <IonSelectOption value="2024">2024</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Месяц</IonLabel>
                                        <IonSelect value={month} placeholder="Выберите месяц" okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       const value = e.detail.value;
                                                       setMonth(value);
                                                       props.change({year, month: value});
                                                   }}>
                                            <IonSelectOption value="01">Январь</IonSelectOption>
                                            <IonSelectOption value="02">Февраль</IonSelectOption>
                                            <IonSelectOption value="03">Март</IonSelectOption>
                                            <IonSelectOption value="04">Апрель</IonSelectOption>
                                            <IonSelectOption value="05">Май</IonSelectOption>
                                            <IonSelectOption value="06">Июнь</IonSelectOption>
                                            <IonSelectOption value="07">Июль</IonSelectOption>
                                            <IonSelectOption value="08">Август</IonSelectOption>
                                            <IonSelectOption value="09">Сентябрь</IonSelectOption>
                                            <IonSelectOption value="10">Октябрь</IonSelectOption>
                                            <IonSelectOption value="11">Ноябрь</IonSelectOption>
                                            <IonSelectOption value="12">Декабрь</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <p>{monthInfo}</p>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    )
}

export default BillingPeriodWidget;