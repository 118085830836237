import React from 'react';
import {IonApp, IonContent, setupIonicReact} from '@ionic/react';

/* Core CSS required for Ionic component to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';
import SalaryCalculator from './component/SalaryCalculator'
import AppHeader from "./component/AppHeader";

setupIonicReact({
    mode: 'md',
});

function App() {
    return (
        <IonApp>
            <AppHeader version={process.env.REACT_APP_VERSION}/>
            <IonContent className="ion-padding">
                <SalaryCalculator/>
            </IonContent>
        </IonApp>
    );
}

export default App;
