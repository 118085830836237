import {IonAlert, IonButton, IonIcon} from "@ionic/react";
import {informationCircle} from "ionicons/icons";
import React from "react";

function AppVersionInfo(props) {
    return (<>
            <IonButton id="version-info-alert">
                <IonIcon slot="icon-only" icon={informationCircle}></IonIcon>
            </IonButton>
            <IonAlert
                header={`Текущая версия ${props.version}`}
                trigger="version-info-alert"
                buttons={[
                    {
                        text: 'Закрыть',
                        role: 'confirm'
                    },
                ]}
            ></IonAlert></>

    );
}

export default AppVersionInfo;