import {IonButtons, IonHeader, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect, useState} from "react";
import AppVersionInfo from "./AppVersionInfo";
import AppUpdateInfo from "./AppUpdateInfo";
import {christmasThemeShow} from "../Utils";

function AppHeader(props) {
    const [online, setOnline] = useState(navigator.onLine);
    const setOnlineMode = () => {
        setOnline(true);
    }
    const setOfflineMode = () => {
        setOnline(false);
    }
    useEffect(() => {
        window.addEventListener('online', setOnlineMode);
        window.addEventListener('offline', setOfflineMode);
        return () => {
            window.removeEventListener('online', setOnlineMode);
            window.removeEventListener('offline', setOfflineMode);
        };
    }, []);
    return (
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <img src="/img/logo192.png" alt="logo" className="logo"/>
                </IonButtons>
                <IonTitle>
                    Калькулятор ЗП
                </IonTitle>
                {christmasThemeShow() && (<IonButtons slot="start">
                    <img src="/img/christmas-lights256.png" alt="christmas lights" className="christmas-lights"/>
                </IonButtons>)}
                {christmasThemeShow() && (<IonButtons slot="end">
                    <img src="/img/christmas-tree256.png" alt="christmas lights" className="christmas-tree"/>
                </IonButtons>)}
                <IonButtons slot="end">
                    {online && (<AppUpdateInfo online={online} currentVersion={props.version}></AppUpdateInfo>)}
                    {!online && (<AppVersionInfo version={props.version}></AppVersionInfo>)}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
}

export default AppHeader;