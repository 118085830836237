import React, {useEffect, useState} from 'react';
import {IonGrid, IonLoading} from "@ionic/react";
import PaymentWidget from "./widget/PaymentWidget";
import InputDataWidget from "./widget/InputDataWidget";
import BillingPeriodWidget from "./widget/BillingPeriodWidget";
import {
    calculateCostHour,
    calculateFullPaid,
    calculateSalary,
    calculateWithheld, getBonus,
    getCurrentDate,
    getHourFirstHalfOfMonth,
    getHourOfMonth,
    getHourSecondHalfOfMonth,
    getPaidDate,
    getPrePaidDate
} from "../Utils";

function SalaryCalculator() {
    const [calendarHolidaysData, setCalendarHolidaysData] = useState([]);
    const [calendarMonthInfoData, setCalendarMonthInfoData] = useState([]);
    const [regionalCoefficientData, setRegionalCoefficientData] = useState([]);
    const [northernAllowanceData, setNorthernAllowanceData] = useState([]);
    const [period, setPeriod] = useState(getCurrentDate());
    const [salary, setSalary] = useState("0");
    const [prePaid, setPrePaid] = useState("0");
    const [paid, setPaid] = useState("0");
    const [otherPaid, setOtherPaid] = useState("0");
    const [royaltyPaid, setRoyaltyPaid] = useState("0");
    const [remoteWorkPaid, setRemoteWorkPaid] = useState("0");
    const [costHour, setCostHour] = useState("0");
    const [taxEnabled, setTaxEnabled] = useState('true');
    const [tax, setTax] = useState("13");
    const [northernAllowance, setNorthernAllowance] = useState("0");
    const [regionalCoefficient, setRegionalCoefficient] = useState("0");
    const [loading, setLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [prePaidDate, setPrePaidDate] = useState("-");
    const [paidDate, setPaidDate] = useState("-");
    const [dayPrePaid, setDayPrePaid] = useState('15');
    const [dayPaid, setDayPaid] = useState("01");

    const changePeriod = (date) => {
        setPeriod({...period, year: date.year, month: date.month, full: `${date.year}-${date.month}`})
    }

    useEffect(() => {
        if (!isInit) {
            setLoading(true);
            setIsInit(true);
            fetch('./json/calendar.json')
                .then(response => {
                    return response.json();
                }).then(data => {
                setCalendarHolidaysData([...data.holidays, ...data.nowork]);
                setCalendarMonthInfoData([...data.monthInfo]);
            })
            fetch('./json/regionalCoefficient.json')
                .then(response => {
                    return response.json();
                }).then(data => {
                setRegionalCoefficientData([...data]);
            })
            fetch('./json/northernAllowance.json')
                .then(response => {
                    return response.json();
                }).then(data => {
                setNorthernAllowanceData([...data]);
            })
            setLoading(false);
        }
    }, [isInit]);
    useEffect(() => {
        if (isInit) {
            const cost = calculateCostHour(salary, getHourOfMonth(calendarMonthInfoData, period));
            setCostHour(cost);
            setPrePaidDate(getPrePaidDate(calendarHolidaysData, period, dayPrePaid));
            setPaidDate(getPaidDate(calendarHolidaysData, period, dayPaid));
            setPrePaid(calculateSalary(cost, getHourFirstHalfOfMonth(calendarMonthInfoData, period), tax, northernAllowance, regionalCoefficient));
            setPaid(calculateSalary(cost, getHourSecondHalfOfMonth(calendarMonthInfoData, period), tax, northernAllowance, regionalCoefficient, otherPaid, royaltyPaid, remoteWorkPaid));
        }
    }, [salary, period.year, period.month, tax, calendarHolidaysData, costHour, calendarMonthInfoData, period, isInit, northernAllowance, regionalCoefficient, otherPaid, royaltyPaid, remoteWorkPaid, dayPrePaid, dayPaid]);
    return (
        <>
            {loading && <IonLoading
                isOpen={loading}
                message={'Инициализация'}
            />}
            {!loading &&
                <IonGrid>
                    <InputDataWidget salary={salary}
                                     setSalary={setSalary}
                                     tax={tax}
                                     setTax={setTax}
                                     taxEnabled={taxEnabled}
                                     setTaxEnabled={setTaxEnabled}
                                     northernAllowanceData={northernAllowanceData}
                                     northernAllowance={northernAllowance}
                                     setNorthernAllowance={setNorthernAllowance}
                                     regionalCoefficientData={regionalCoefficientData}
                                     regionalCoefficient={regionalCoefficient}
                                     setRegionalCoefficient={setRegionalCoefficient}
                                     otherPaid={otherPaid}
                                     setOtherPaid={setOtherPaid}
                                     royaltyPaid={royaltyPaid}
                                     setRoyaltyPaid={setRoyaltyPaid}
                                     remoteWorkPaid={remoteWorkPaid}
                                     setRemoteWorkPaid={setRemoteWorkPaid}
                                     dayPrePaid={dayPrePaid}
                                     setDayPrePaid={setDayPrePaid}
                                     dayPaid={dayPaid}
                                     setDayPaid={setDayPaid}/>
                    <BillingPeriodWidget period={period}
                                         change={changePeriod}
                                         calendarMonthInfoData={calendarMonthInfoData}/>
                    <PaymentWidget prePaidDate={prePaidDate}
                                   prePaid={prePaid}
                                   paidDate={paidDate}
                                   paid={paid}
                                   fullPaid={calculateFullPaid(prePaid, paid)}
                                   withheld={calculateWithheld(salary, tax, otherPaid, royaltyPaid)}/>
                </IonGrid>
            }
        </>
    );
}

export default SalaryCalculator;