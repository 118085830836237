import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import React from "react";
import {isTrue, parsePositiveIntegerNumber} from "../../Utils";

function InputDataWidget(props) {
    return (
        <IonRow class="ion-justify-content-center">
            <IonCol size="12" size-xs="12" size-sm="12" size-md="10" size-lg="4">
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Оклад, НДФЛ и надбавки</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            <IonRow class="ion-justify-content-center">
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Оклад, руб.</IonLabel>
                                        <IonInput fill="outline" value={props.salary}
                                                  onIonInput={(e) => {
                                                      const filteredValue = parsePositiveIntegerNumber(e.detail.value);
                                                      e.target.value = filteredValue;
                                                      props.setSalary(filteredValue)
                                                  }}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6" size-xs="12" size-sm="6" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Учитывать НДФЛ</IonLabel>
                                        <IonSelect value={props.taxEnabled} okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       const taxEnabled = e.detail.value;
                                                       if (isTrue(taxEnabled)) {
                                                           props.setTax('13')
                                                       } else {
                                                           props.setTax('0')
                                                       }
                                                       props.setTaxEnabled(taxEnabled)
                                                   }}>
                                            <IonSelectOption value="true">Да</IonSelectOption>
                                            <IonSelectOption value="false">Нет</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                {isTrue(props.taxEnabled) && <IonCol size="6" size-xs="12" size-sm="6" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">НДФЛ, %</IonLabel>
                                        <IonSelect value={props.tax} placeholder="Выберите %" okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       props.setTax(e.detail.value)
                                                   }}>
                                            <IonSelectOption value="9">9</IonSelectOption>
                                            <IonSelectOption value="13">13</IonSelectOption>
                                            <IonSelectOption value="15">15</IonSelectOption>
                                            <IonSelectOption value="30">30</IonSelectOption>
                                            <IonSelectOption value="35">35</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>}
                            </IonRow>
                            <IonRow class="ion-justify-content-center">
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Северная надбавка, %</IonLabel>
                                        <IonSelect value={props.northernAllowance} okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       props.setNorthernAllowance(e.detail.value)
                                                   }}>
                                            {props.northernAllowanceData.map((item) => {
                                                return <IonSelectOption key={item} value={item.toString()}>{item}</IonSelectOption>
                                            })}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Районный коэффициент</IonLabel>
                                        <IonSelect value={props.regionalCoefficient} okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       props.setRegionalCoefficient(e.detail.value)
                                                   }}>
                                            {props.regionalCoefficientData.map((item) => {
                                                return <IonSelectOption key={item} value={item.toString()}>{item}</IonSelectOption>
                                            })}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Авторское вознаграждение, руб.</IonLabel>
                                        <IonInput value={props.royaltyPaid}
                                                  onIonInput={(e) => {
                                                      const filteredValue = parsePositiveIntegerNumber(e.detail.value);
                                                      e.target.value = filteredValue;
                                                      props.setRoyaltyPaid(filteredValue)
                                                  }}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Компенсация за дистанционную работу, руб.</IonLabel>
                                        <IonInput value={props.remoteWorkPaid}
                                                  onIonInput={(e) => {
                                                      const filteredValue = parsePositiveIntegerNumber(e.detail.value);
                                                      e.target.value = filteredValue;
                                                      props.setRemoteWorkPaid(filteredValue)
                                                  }}/>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">Иные доплаты, руб.</IonLabel>
                                        <IonInput value={props.otherPaid}
                                                  onIonInput={(e) => {
                                                      const filteredValue = parsePositiveIntegerNumber(e.detail.value);
                                                      e.target.value = filteredValue;
                                                      props.setOtherPaid(filteredValue)
                                                  }}/>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow class="ion-justify-content-center">
                                <IonCol size="6" size-xs="12" size-sm="6" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">День аванса, число</IonLabel>
                                        <IonSelect value={props.dayPrePaid} placeholder="Выберите число" okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       props.setDayPrePaid(e.detail.value)
                                                   }}>
                                            <IonSelectOption value="15">15</IonSelectOption>
                                            <IonSelectOption value="16">16</IonSelectOption>
                                            <IonSelectOption value="17">17</IonSelectOption>
                                            <IonSelectOption value="18">18</IonSelectOption>
                                            <IonSelectOption value="19">19</IonSelectOption>
                                            <IonSelectOption value="20">20</IonSelectOption>
                                            <IonSelectOption value="21">21</IonSelectOption>
                                            <IonSelectOption value="22">22</IonSelectOption>
                                            <IonSelectOption value="23">23</IonSelectOption>
                                            <IonSelectOption value="24">24</IonSelectOption>
                                            <IonSelectOption value="25">25</IonSelectOption>
                                            <IonSelectOption value="26">26</IonSelectOption>
                                            <IonSelectOption value="27">27</IonSelectOption>
                                            <IonSelectOption value="28">28</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6" size-xs="12" size-sm="6" size-md="6" size-lg="6">
                                    <IonItem fill="outline">
                                        <IonLabel position="stacked">День зарплаты, число</IonLabel>
                                        <IonSelect value={props.dayPaid} placeholder="Выберите число" okText="Ок"
                                                   cancelText="Отмена"
                                                   onIonChange={(e) => {
                                                       props.setDayPaid(e.detail.value)
                                                   }}>
                                            <IonSelectOption value="01">1</IonSelectOption>
                                            <IonSelectOption value="02">2</IonSelectOption>
                                            <IonSelectOption value="03">3</IonSelectOption>
                                            <IonSelectOption value="04">4</IonSelectOption>
                                            <IonSelectOption value="05">5</IonSelectOption>
                                            <IonSelectOption value="06">6</IonSelectOption>
                                            <IonSelectOption value="07">7</IonSelectOption>
                                            <IonSelectOption value="08">8</IonSelectOption>
                                            <IonSelectOption value="09">9</IonSelectOption>
                                            <IonSelectOption value="10">10</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    )
}

export default InputDataWidget;